<!--  -->
<template>
  <div>
    <!-- <headerTop></headerTop> -->
    <div class="froms">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <div class="title">欢迎注册玩子云账号</div>
        <div class="tips">我们在您提交注册信息后24小时内会进行信息核对，届时请您留意注册激活邮件。</div>
        <div class="step">
          <div class="step1">
            <div class="red">1</div>
            <div class="tips_min">注册填写</div>
          </div>
          <div class="step2">
            <div class="red red_no">2</div>
            <div class="tips_min">信息审核</div>
          </div>
        </div>
        <div class="title2" style="margin-top: 80rem;">企业信息</div>
        <div class="ipt1">
          <div class="title3">企业名称</div>
          <div style="width:508rem;">
            <el-form-item prop="input1">
              <el-input v-model="ruleForm.input1" placeholder="请输入您所在企业的名字全称"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title4">企业所在地区</div>
          <div style="margin-top: 20rem; display:flex;">
            <el-form-item prop="input11" style="margin-right: 20rem;">
              <el-select v-model="ruleForm.input11" filterable placeholder="请选择国家/地区" @change="change1">
                <el-option :label="item.CountryName+' - '+item.CountryName_CN" :value="item.id" v-for="(item,index) in countriesList3" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="input2" style="margin-right: 20rem;">
              <el-select v-model="ruleForm.input2" filterable placeholder="请选择省" @change="change2">
                <el-option :label="item.name+' - '+item.name_cn" :value="item.id" v-for="(item,index) in countriesList" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="input10" >
              <el-select v-model="ruleForm.input10" filterable placeholder="请选择市" @change="change3">
                <el-option :label="item.name+' - '+item.name_cn" :value="item.id" v-for="(item,index) in countriesList2"></el-option>
              </el-select>
            </el-form-item>
          </div>
        <div class="ipt2">
          
        </div>
        <div class="ipt1">
          <div class="title3">详细地址</div>
          <div style="width:508rem;">
            <el-form-item prop="input3">
              <el-input :rows="3" v-model="ruleForm.input3"  placeholder="请输入详细地址（具体到门牌号）"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title2">账号信息</div>
        <div class="ipt1">
          <div class="title3">用户名</div>
          <div style="width:508rem;">
            <el-form-item prop="input4">
              <el-input v-model="ruleForm.input4" placeholder="请设置一个用户名"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="ipt1">
          <div class="title3">登录密码</div>
          <div style="width:508rem;">
            <el-form-item prop="input5">
              <el-input v-model="ruleForm.input5" placeholder="请设置您的登录密码（6-20位）"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="ipt1">
          <div class="title3">邮箱</div>
          <div style="width:508rem;">
            <el-form-item prop="input6">
              <el-input v-model="ruleForm.input6" placeholder="请输入您的常用邮箱"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="ipt1">
          <div class="title3">联系人</div>
          <div class="name" style="flex:1;">
            <el-form-item prop="input7" style="flex:1;">
              <el-input v-model="ruleForm.input7" placeholder="姓氏"></el-input>
            </el-form-item>
            <div style="width:20rem;"></div>
            <el-form-item prop="input8" style="flex:1;">
              <el-input v-model="ruleForm.input8" placeholder="名字"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="ipt1">
          <div class="title3">手机号</div>
          <div style="width:508rem;">
            <el-form-item prop="input9">
              <el-input v-model="ruleForm.input9" placeholder="请输入您的常用手机号码">
                <div style="width:80px;" slot="prepend">
                  <el-select v-model="ruleForm.mobile_head"   placeholder="请选择">
                    <el-option :label="item.mobile_head+' '+item.CountryName_CN" :value="item.mobile_head" v-for="(item,index) in countriesList3" ></el-option>
                  </el-select>
                </div>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="ipt1">
          <div class="title3"></div>
          <div style="width:508rem;"><el-checkbox v-model="ruleForm.checked">我已阅读并同意 《服务条款》 及 《当天房预订规则》</el-checkbox></div>
        </div>
        <div class="ipt1">
          <!-- <div class="title3"></div> -->
          <div class="register" @click="submitForm('ruleForm')">
            提交注册
          </div>
        </div>
        <div class="yy" @click="login">
          已有注册账号？去登录
        </div>
      </el-form>
    </div>
    <fottom></fottom>
  </div>
</template>

<script>
import headerTop from '@/components/headerTop.vue'
import fottom from '@/components/fottom.vue'
export default {
  data () {
    return {
      ruleForm: {
        input1:'',
        input2:'',
        input3:'',
        input4:'',
        input5:'',
        input6:'',
        input7:'',
        input8:'',
        input9:'',
        input10:'',
        input11:'',
        checked:false,
        mobile_head:'+86'
      },
      rules: {
        input1: [
          { required: true, message: '请输入您所在企业的名字全称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        mobile_head: [
          { required: true, message: '请选择手机区号', trigger: 'change' }
        ],
        input2: [
          { required: true, message: '请选择企业所省', trigger: 'change' }
        ],
        input10: [
          { required: true, message: '请选择企业所在市', trigger: 'change' }
        ],
        input11: [
          { required: true, message: '请选择企业所在国家', trigger: 'change' }
        ],
        input3: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ],
        input4: [
          { required: true, message: '请设置一个用户名', trigger: 'blur' }
        ],
        input5: [
          { required: true, message: '请设置您的登录密码', trigger: 'blur' }
        ],
        input6: [
          { required: true, message: '请输入您的常用邮箱', trigger: 'blur' }
        ],
        input7: [
          { required: true, message: '请填写您的姓氏', trigger: 'blur' }
        ],
        input8: [
          { required: true, message: '请填写您的名字', trigger: 'blur' }
        ],
        input9: [
          { required: true, message: '请输入您的常用手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号必须是11位数字', trigger: 'blur' }
        ],
      },
      // 国家列表
      countriesList:[],
      countriesList2:[],
      countriesList3:[],
      counTry:{},
      provInce:{},
      city:{},
      
    };
  },

  components: {
    headerTop,
    fottom
  },

  computed: {},

  mounted(){
    this.country()
    // this.address()
  },

  methods: {
    login(){
      window.location.href = 'https://pc.wanzicloud.com/login'
    },
    // 获取国家
    country(){
      let that = this
      this.$http({
          method:'get',
          url:'https://api.wanzicloud.com/api/admin/apply/getAddress?type='+1
      }).then(res=>{
        if(res.data.code == 1){
          that.countriesList3 = res.data.data
        }else{
          that.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    // 获取省
    address(e){
      let that = this
      this.$http({
          method:'get',
          url:'https://api.wanzicloud.com/api/admin/apply/getAddress?type='+2+'&pid='+e
      }).then(res=>{
        if(res.data.code == 1){
          that.countriesList = res.data.data
        }else{
          that.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    // 获取市
    address2(e){
      let that = this
      this.$http({
          method:'get',
          url:'https://api.wanzicloud.com/api/admin/apply/getAddress?type='+3+'&pid='+e
      }).then(res=>{
        if(res.data.code == 1){
          that.countriesList2 = res.data.data
        }else{
          that.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    change1(e){
      this.address(e)
      this.ruleForm.input2 = ''
      this.ruleForm.input10 = ''
      this.counTry = this.countriesList3.filter(item=>{
        return item.id == e
      })
      this.counTry = this.counTry[0]
      this.provInce = {}
      this.city = {}
    },
    change2(e){
      // var newArr = this.countriesList.filter(function(item){
      //   return item.id == e;	// 过滤出来数组中>=5的元素
      // })
      // this.ruleForm.input2 = newArr[0].name
      this.address2(e)
      this.ruleForm.input10 = ''
      this.provInce = this.countriesList.filter(item=>{
        return item.id == e
      })
      this.provInce = this.provInce[0]
      this.city = {}
    },
    change3(e){
      this.city = this.countriesList2.filter(item=>{
        return item.id == e
      })
      this.city = this.city[0]
    },
    submitForm(formName) {
      // this.$router.push('/tips')
      let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!that.ruleForm.checked){
            that.$message({
              message: '请勾选并同意 《服务条款》 及 《当天房预订规则》',
              type: 'error'
            });
            return
          }
          that.$http({
              method:'POST',
              url:'https://api.wanzicloud.com/api/admin/apply/add',
              data:{
                company_name:that.ruleForm.input1,
                country:that.counTry.CountryName_CN,
                country_id:that.counTry.id,
                country_code:that.counTry.ISOCode,
                province:that.provInce.name_cn,
                province_id:that.provInce.id,
                city:that.city.name_cn,
                city_id:that.city.id,
                address:that.ruleForm.input3,
                name:that.ruleForm.input4,
                password:that.ruleForm.input5,
                email:that.ruleForm.input6,
                lastname:that.ruleForm.input7,
                firstname:that.ruleForm.input8,
                phone:that.ruleForm.input9,
                mobile_head:that.ruleForm.mobile_head,
              }
          }).then(res=>{
            if(res.data.code == 1){
              that.$message({
                message: '提交成功',
                type: 'success'
              });
              that.$router.push('/tips')
            }else{
              that.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
            console.log(res);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}

</script>
<style scoped>
/* /deep/.el-input--suffix{
  width: 120px;
} */
.el-form-item{
  margin-bottom: 0;
}
.froms{
  width: 588rem;
  margin: auto;
  margin-top: 30rem;
  padding-top: 1rem;
  box-sizing: border-box;
}
.title{
  font-weight: 600;
  font-size: 40rem;
  color: #000000;
}
.tips{
  font-weight: 400;
  font-size: 16rem;
  color: #686F86;
  margin-top: 10rem;
}
.step{
  width: 300rem;
  /* display: flex;
  justify-content: space-between; */
  border-top: 2rem solid #DBE3E8;
  position: relative;
  margin-top: 30rem;
  margin-left: 160rem;
}
.step1{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -11rem;
  left: -22rem;
}
.step2{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -11rem;
  right: -22rem;
}
.red{
  width: 22rem;
  height: 22rem;
  background: #EA4C6F;
  font-weight: 400;
  font-size: 12rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 22rem;
  border-radius: 50%;
}
.red_no{
  background: #DBE3E8;
  color: #343F5B;
}
.tips_min{
  font-weight: 400;
  font-size: 14rem;
  color: #686F86;
  margin-top: 5rem;
}
.title2{
  font-weight: 600;
  font-size: 16rem;
  color: #000000;
  margin-top: 50rem;
  margin-bottom: 16rem;
}
.ipt1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24rem;
}
.title3{
  width: 80rem;
  font-weight: 400;
  font-size: 16rem;
  color: #686F86;
}
.ipt2{
  display: flex;
  /* align-items: center; */
  margin-bottom: 24rem;
}
.title4{
  font-weight: 400;
  font-size: 16rem;
  color: #686F86;
}
.name{
  width:508rem;
  display: flex;
  align-items: center;
}
.register{
  width: 100%;
  height: 42rem;
  background-image: linear-gradient(87deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
  border-radius: 21rem;
  font-weight: 500;
  font-size: 16rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 42rem;
  cursor:pointer;
}
.yy{
  font-weight: 400;
  font-size: 14rem;
  color: #000000;
  text-align: right;
  margin-top: -10rem;
  margin-bottom: 163rem;
  cursor:pointer;
}
</style>